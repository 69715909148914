import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-234268a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 1,
  class: "numberInfoTitle"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 3,
  class: "numberInfoSubTitle"
}
const _hoisted_5 = {
  key: 0,
  class: "suffix"
}
const _hoisted_6 = {
  key: 0,
  class: "subTotal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['numberInfo', { [`numberInfo${_ctx.theme}`]: _ctx.theme }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "numberInfoTitle",
          title: _ctx.title
        }, _toDisplayString(_ctx.title), 9, _hoisted_1))
      : (_ctx.$slots.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
    (_ctx.subTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "numberInfoSubTitle",
          title: _ctx.subTitle
        }, _toDisplayString(_ctx.subTitle), 9, _hoisted_3))
      : (_ctx.$slots.subTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "subTitle", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "numberInfoValue",
      style: _normalizeStyle({ marginTop: _ctx.gap ? `${_ctx.gap}px` : 'none' })
    }, [
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.total) + " ", 1),
        (_ctx.suffix)
          ? (_openBlock(), _createElementBlock("em", _hoisted_5, _toDisplayString(_ctx.suffix), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.subTotal || _ctx.status)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.subTotal) + " ", 1),
            (_ctx.status === 'up')
              ? (_openBlock(), _createBlock(_component_caret_up_outlined, { key: 0 }))
              : (_openBlock(), _createBlock(_component_caret_down_outlined, { key: 1 }))
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ], 2))
}