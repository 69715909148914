
import { defineComponent, PropType } from 'vue';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue';

export type StatusType = 'up' | 'down' | string;

export type ThemeType = 'light' | 'dark' | string;

export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => undefined,
    },
    subTitle: {
      type: String,
      default: () => undefined,
    },
    total: {
      type: [String, Number] as PropType<string | number>,
      default: 0,
    },
    status: {
      type: String as PropType<StatusType>,
      default: 'up',
      validator: function (value: StatusType) {
        return ['up', 'down'].indexOf(value) !== -1;
      },
    },
    theme: {
      type: String as PropType<ThemeType>,
      default: 'light',
    },
    gap: {
      type: Number,
      default: 0,
    },
    subTotal: {
      type: [Number, String],
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
  },
});
