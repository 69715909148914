
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { TimelineChart } from './charts';
import NumberInfo from './number-info/index.vue';
import { OfflineDataItem, OfflineChartDataItem } from '@/api/dashboard/analysis';

export default defineComponent({
  props: {
    activeKey: {
      type: String as PropType<string>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    offlineData: {
      type: Array as PropType<OfflineDataItem[]>,
      default: () => [],
    },
    offlineChartData: {
      type: Array as PropType<OfflineChartDataItem[]>,
      default: () => [],
    },
    handleTabChange: {
      type: Function as PropType<(e: Event) => void>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  components: {
    TimelineChart,
    NumberInfo,
  },
});
