import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"0 24px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_info = _resolveComponent("number-info")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_timeline_chart = _resolveComponent("timeline-chart")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    class: "offlineCard",
    bordered: false,
    style: {"margintop":"32px"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        "active-key": _ctx.activeKey,
        onChange: _ctx.handleTabChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offlineData, (shop) => {
            return (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: shop.name
            }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, {
                  gutter: 8,
                  style: {"width":"70px","margin":"8px 0"},
                  type: "flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_number_info, {
                          title: shop.name,
                          "sub-title": _ctx.t('dashboard.analysis.conversion-rate'),
                          gap: 2,
                          total: `${shop.cvr * 100}%`,
                          theme: _ctx.activeKey !== shop.name ? 'light' : undefined
                        }, null, 8, ["title", "sub-title", "total", "theme"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_timeline_chart, {
                    height: 400,
                    data: _ctx.offlineChartData,
                    "title-map": {
              y1: _ctx.t('dashboard.analysis.traffic'),
              y2: _ctx.t('dashboard.analysis.payments'),
            }
                  }, null, 8, ["data", "title-map"])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["active-key", "onChange"])
    ]),
    _: 1
  }, 8, ["loading"]))
}